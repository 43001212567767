import {Injectable} from "@angular/core";
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
import {Observable} from "rxjs";
import {CookieService} from "ngx-cookie-service";
import {UserService} from "../services/user.service";
import * as url from "url";

@Injectable({
    providedIn: "root",
})
export class AdminGuard implements CanActivate {
    constructor(public router: Router, private cookieService: CookieService, private userService: UserService) {
    }

    userDefinedUrls =
        {
            Management: ['/**'],
            Marketing: ['students'],
            Certificate: ['students'],
            Accounting: ['/**'],
            IT: ['user'],
            test: ['students']
        }

    userDefaultRouting = {
        Marketing: '/students/register',
        Certificates: '/students/register/',
        IT: 'user/register',
        Management: 'Dashboard',
        Accounting: 'Dashboard',
        test: 'students/register'
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // Guard for user is login or not
        let user = this.cookieService.get('access');
        if (!user) {
            this.router.navigateByUrl('/auth/login');
            return true;
        } else {
            console.log('url is', state.url)
            console.log('user type is ', this.userService.getUserType());
            if (this.checkAccessPrivellage(state.url, this.userService.getUserType())) {
                console.log('i went there')

                return true;
            } else {
                this.router.navigateByUrl(this.getUserTypeDefaultRoute(this.userService.getUserType()))
                return true;
            }
        }

        return true;
    }


    checkAccessPrivellage(url, userType): boolean {
        console.log(url)
        let canAccess = false
        this.userDefinedUrls[userType].forEach(allowedUrl => {
            console.log(allowedUrl)
            if (allowedUrl == '/**') {
                canAccess = true
                return canAccess
            } else if (url.includes(allowedUrl)) {
                canAccess = true
                return canAccess
            }
        })
        return canAccess
    }

    getUserTypeDefaultRoute(userType): string {
        return this.userDefaultRouting[userType];
    }


}
