import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import Swal from 'sweetalert2'; // Import the cookie service if needed

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

    constructor(private router: Router, private cookieService: CookieService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 && error.error.code === 'token_not_valid') {
                    // Handle the token expiration or invalid token scenario
                    const tokenErrorMessage = error.error.messages && error.error.messages[0]?.message;
                    Swal.fire({
                        icon: 'error',
                        text: 'Expired Session , please login again!',
                    });
                    if (tokenErrorMessage === 'Token is invalid or expired') {
                        // Remove token if needed
                        this.cookieService.delete('access', '/');

                        // Redirect to login page
                        this.router.navigate(['/auth/login']);
                    }
                }
                // Pass the error to the next handler
                return throwError(() => error);
            })
        );
    }
}
