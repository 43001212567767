import {HttpClient} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {FormInput} from 'src/app/shared/model/form.model';
import {executeExtractI18nBuilder} from "@angular-devkit/build-angular";
import {environment} from "../../../../../environments/environment";

@Component({
    selector: 'app-default-form',
    templateUrl: './default-form.component.html',
    styleUrls: ['./default-form.component.scss']
})
export class DefaultFormComponent implements OnInit {

    @Input() formItems: FormInput[];
    @Input() module: any;
    @Input() inputStream: any;
    @Input() isReadOnly: boolean = false;
    public instructorList: any[] = [];
    public courseCategoryList: any [] = [];
    public accreditationEntityList: any [] = [];
    public nationalityList: any [] = [];
    public studentCategoryList: any [] = [];
    public courseList: any [] = [];
    public studentList: any [] = [];
    public paymentTypeList: any [] = [];
    public organizationsList: any [] = [];
    public groupList: any[] = [];

    @Output() formSubmitted = new EventEmitter<any>();
    @Output() formReviewed = new EventEmitter<any>();
    @Output() fileUploadEvent = new EventEmitter<any>();
    form: FormGroup;


    constructor(private fb: FormBuilder, private httpClient: HttpClient) {


    }

    ngOnInit(): void {

        console.log(this.formItems);
        this.buildForm();
    }

    buildForm(): void {
        console.log('form items', this.formItems);
        const formGroup = {};
        this.formItems.forEach(item => {
            formGroup[item.name] = new FormControl(item.value, item.validators);
        });

        this.form = this.fb.group(formGroup);

    }

    onDropdownSearch(item: FormInput, searchTerm: string): void {
        if (item.onSearch) {
            item.onSearch(searchTerm);
        }
    }


    onSubmit() {
        this.formSubmitted.emit(this.form);

    }

    public getDropDownList(name: any): any[] {
        switch (name) {
            case 'instructor':
                return this.instructorList;
            case 'category':
                return this.courseCategoryList;
            case 'course':
                return this.courseList;
            case 'accreditationEntity':
                return this.accreditationEntityList;
            case 'student':
                return this.studentList;
            case  'paymentType':
                return this.paymentTypeList;
            case  'group':
                return this.groupList;
            case  'organization':
                return this.organizationsList;

        }
    }

    updateData(event: any) {
        if (this.module == 'course') {
            this.instructorList = this.inputStream.instructor;
            this.courseCategoryList = this.inputStream.category;
            this.accreditationEntityList = this.inputStream.accreditationEntity;
        } else if (this.module == 'student') {
            this.nationalityList = this.inputStream.nationality;
            this.studentCategoryList = this.inputStream.category;
        } else if (this.module == 'invoice') {
            this.courseList = this.inputStream.course;
            this.studentList = this.inputStream.student;
            this.paymentTypeList = this.inputStream.paymentType;
            this.organizationsList = this.inputStream.organization;
        } else if (this.module == 'user') {
            this.groupList = this.inputStream.group;

        }

    }

    onReviewApproval() {
        this.formReviewed.emit('reviewed')
    }

    onFileUpload(event: any, fileName: any) {
        const formData = new FormData();
        let file: File = event.target.files[0];
        const defaultFileName = file.name;
        const fileExtension = defaultFileName.substring(defaultFileName.lastIndexOf('.'));
        let modifiedFile = new File([file], fileName + fileExtension)
        this.fileUploadEvent.emit(modifiedFile);


    }


}

