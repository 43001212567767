// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    // apiUrl: 'http://165.227.160.188:8000/',
    // apiUrl: 'http://192.168.1.2:8000/',
    // apiUrl: 'http://172.20.10.3:8000/',
    apiUrl: 'https://administration.albyaninstitute.com/',

};

export const companyConfig = {
    company: 'Albyan Institute Education Support Services',
    arabicName: 'معهد البيان للخدمات التعليمية',
    aliasName: 'KAYAN COLLEGE FOR INTERNATIONAL SECURITY AND LEGAL SCIENCES',
    email: 'info@albyaninstitute.org',
    phone: '+971 56 900 5544',
    address: 'Business Avenue - Port Saeed Road, Dubai, United Arab Emirates',
    trnNumber: '100245077100003',
    bankAccountName: 'Al-Bayan Institute Education Support Services',
    branchName: 'Al Maktoum Branch',
    bankName: 'Emirates NBD',
    bankAccountNumber: '101-469-429-6101',
    bankIBAN: 'AE100260001014694296101',
    swiftCode: 'EBILAEAD',
    currency: 'AED',
};
