import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import Swal from "sweetalert2"; // Optional, if you want to clear cookies

@Injectable()
export class ForbiddenInterceptorService implements HttpInterceptor {

    constructor(private router: Router, private cookieService: CookieService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 403) {
                    Swal.fire({
                        icon: 'error',
                        text: 'You Do not have permission to View this!',
                    });

                    this.cookieService.delete('access', '/');

                    this.router.navigate(['/auth/login']);
                }

                // Pass the error to the next handler
                return throwError(() => error);
            })
        );
    }
}
