<div class="media profile-media">
    <img class="b-r-10" [src]="user?.profilePic" alt="" width="40px" style="border-radius: 50%; object-fit: cover;">
    <div class="media-body"><span>{{ user?.username }}</span>
        <p class="mb-0 font-roboto">{{ userGroup }} <i class="middle fa fa-angle-down"></i></p>
    </div>
</div>
<ul class="profile-dropdown onhover-show-div">
    <li><a href="/user/profile">
        <app-feather-icons [icon]="'user'"></app-feather-icons>
        <span>Account </span></a></li>
    <li (click)="logoutFunc()">
        <app-feather-icons [icon]="'log-in'"></app-feather-icons>
        <span>Log out</span></li>
</ul>
