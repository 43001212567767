<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3>{{ title }}</h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a *ngIf="isUserAllowed(); else notAllowed" [routerLink]="'/dashboard'">
                            <svg class="stroke-icon">
                                <use href="assets/svg/icon-sprite.svg#stroke-home"></use>
                            </svg>
                        </a>
                        <ng-template #notAllowed>
                            <a href="javascript:void(0)" (click)="onUnauthorizedAccess()">
                                <svg class="stroke-icon">
                                    <use href="assets/svg/icon-sprite.svg#stroke-home"></use>
                                </svg>
                            </a>
                        </ng-template>
                    </li>
                    <li class="breadcrumb-item" *ngFor="let item of items">{{ item }}</li>
                    <li class="breadcrumb-item active">{{ active_item }}</li>
                </ol>
            </div>
        </div>
    </div>
</div>
