import {Component, OnInit, Input} from '@angular/core';
import {UserService} from "../../services/user.service";
import Swal from "sweetalert2";

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

    @Input() title: string;
    @Input() items: any[];
    @Input() active_item: string;

    userGroups: string[] = [];
    allowedGroups = ['Accounting', 'Management'];


    loadUserGroups() {
        const userData = this.userService.getUserGroups(); // Assuming this method returns the list of groups
        if (userData) {
            this.userGroups = userData;
        }
    }

    constructor(private userService: UserService) {
    }


    ngOnInit() {
        this.loadUserGroups();
    }

    isUserAllowed(): boolean {
        return this.userGroups.some(group => this.allowedGroups.includes(group));
    }

    onUnauthorizedAccess(): void {
        Swal.fire({
            icon: 'warning',
            title: 'Not Allowed',
            text: 'You do not have permission to access this page!',
        });
    }

}
