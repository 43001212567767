<app-breadcrumb [title]="'Form Wizard'" [items]="['Forms', 'Form Layout']" [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h5>Simple Form Wizard</h5><span>Please Make sure fill all the filed before click on next button</span>
        </div>
        <div class="card-body">
          <form class="form-wizard" id="regForm">
            <aw-wizard [disableNavigationBar]='true'>
              <aw-wizard-step>
                <form [formGroup]="firstFormGroup">
                  <div class="mb-3">
                    <label for="name">First Name</label>
                    <input class="form-control" id="name" type="text" placeholder="Johan" required="required"
                      formControlName="firstName" onlyAlphabets>
                    <ng-container class="invalid"
                      *ngIf="firstFormGroup.get('firstName').hasError('required') && firstFormGroup.get('firstName').touched">
                      First Name is required</ng-container>
                  </div>
                  <div *ngIf=""></div>
                  <div class="mb-3">
                    <label for="lname">Last Name</label>
                    <input class="form-control" id="lname" type="text" placeholder="Deo" formControlName="lastName"
                      onlyAlphabets>
                    <ng-container class="invalid"
                      *ngIf="firstFormGroup.get('lastName').hasError('required') && firstFormGroup.get('lastName').touched">
                      Last Name is required</ng-container>
                  </div>
                  <div class="mb-3">
                    <label for="contact">Contact No.</label>
                    <input class="form-control digits" id="contact" type="text" placeholder="123456789"
                      formControlName="contactNumber" onlyNumbers>
                    <ng-container class="invalid"
                      *ngIf="firstFormGroup.get('contactNumber').hasError('required') && firstFormGroup.get('contactNumber').touched">
                      Contact is required</ng-container>
                  </div>
                  <button class="btn btn-primary pull-right" type="submit" awNextStep
                    [disabled]="!firstFormGroup.valid">Next</button>
                </form>
              </aw-wizard-step>
              <aw-wizard-step>
                <form [formGroup]="secondFormGroup">
                  <div class="mb-3 m-t-15">
                    <label for="exampleFormControlInput1">Email address</label>
                    <input class="form-control" id="exampleFormControlInput1" type="email"
                      placeholder="name@example.com" formControlName="email">
                    <ng-container class="invalid"
                      *ngIf="secondFormGroup.get('email').hasError('required') && secondFormGroup.get('email').touched">
                      Email is required</ng-container>
                    <ng-container class="invalid"
                      *ngIf="secondFormGroup.get('email').hasError('email') && secondFormGroup.get('email').touched">
                      Email is not Proper</ng-container>
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputPassword1">Password</label>
                    <input class="form-control" id="exampleInputPassword1" type="password" placeholder="Password"
                      formControlName="password">
                    <ng-container class="invalid"
                      *ngIf="secondFormGroup.get('password').hasError('required') && secondFormGroup.get('password').touched">
                      Password is required</ng-container>
                  </div>
                  <div class="mb-3" *ngIf="secondFormGroup.get('password').valid">
                    <label for="exampleInputPassword1">Confirm Password</label>
                    <input class="form-control" id="exampleInputcPassword1" type="password" placeholder="Enter again"
                      formControlName="cnfPassword">
                    <ng-container class="invalid"
                      *ngIf="secondFormGroup.get('cnfPassword').hasError('required') && secondFormGroup.get('cnfPassword').touched">
                      Confirm Password is required</ng-container>
                    <ng-container class="invalid"
                      *ngIf="secondFormGroup.get('cnfPassword').hasError('mustMatch') && secondFormGroup.get('cnfPassword').touched">
                      Passwords Doesnt match</ng-container>
                  </div>
                  <button class="btn btn-primary pull-right" type="button" awNextStep
                    [disabled]="!secondFormGroup.valid">Next</button>
                  <button class="btn btn-secondary pull-right me-1" type="button" awPreviousStep>Previous</button>
                </form>
              </aw-wizard-step>
              <aw-wizard-step>
                <form [formGroup]="thirdFormGroup">
                  <div class="mb-3 custom-datepicker-dropdown custom-datepicker">
                    <label for="exampleFormControlInput1">Birthday:</label>
                    <input class="form-control digits" type="text" formControlName="birthdate" ngbDatepicker
                      #dob="ngbDatepicker" [maxDate]="maxDate" (click)="dob.toggle()">
                    <ng-container class="invalid"
                      *ngIf="thirdFormGroup.get('birthdate').hasError('required') && thirdFormGroup.get('birthdate').touched">
                      Birth Date is required</ng-container>
                  </div>
                  <div class="mb-3">
                    <label class="control-label">Age</label>
                    <input class="form-control digits" placeholder="Age" type="text" formControlName="age">
                  </div>
                  <div class="mb-3">
                    <label class="control-label">Have Passport</label>
                    <input class="form-control digits" placeholder="Yes/No" type="text" formControlName="hasPassport">
                    <ng-container class="invalid"
                      *ngIf="thirdFormGroup.get('hasPassport').hasError('required') && thirdFormGroup.get('hasPassport').touched">
                      Passport field is required</ng-container>
                  </div>
                  <button class="btn btn-primary pull-right" type="button" awNextStep>Next</button>
                  <button class="btn btn-secondary pull-right me-1" type="button" awPreviousStep>Previous</button>
                </form>
              </aw-wizard-step>
              <aw-wizard-completion-step awEnableBackLinks>
                <form [formGroup]="fourthFormGroup">
                  <div class="mb-3">
                    <label class="control-label">Country</label>
                    <input class="form-control mt-1" type="text" placeholder="Country" required="required"
                      formControlName="country">
                    <ng-container class="invalid"
                      *ngIf="fourthFormGroup.get('country').hasError('required') && fourthFormGroup.get('country').touched">
                      Country is required</ng-container>
                  </div>
                  <div class="mb-3">
                    <label class="control-label">State</label>
                    <input class="form-control mt-1" type="text" placeholder="State" required="required"
                      formControlName="state">
                    <ng-container class="invalid"
                      *ngIf="fourthFormGroup.get('state').hasError('required') && fourthFormGroup.get('state').touched">
                      State is required</ng-container>
                  </div>
                  <div class="mb-3">
                    <label class="control-label">City</label>
                    <input class="form-control mt-1" type="text" placeholder="City" required="required"
                      formControlName="city">
                    <ng-container class="invalid"
                      *ngIf="fourthFormGroup.get('city').hasError('required') && fourthFormGroup.get('city').touched">
                      City is required</ng-container>
                  </div>
                  <button class="btn btn-success pull-right" type="button" (click)="finish()"
                    [disabled]="!fourthFormGroup.valid">Finish</button>
                  <button class="btn btn-secondary pull-right me-1" type="button" awPreviousStep>Previous</button>
                </form>
              </aw-wizard-completion-step>
            </aw-wizard>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>