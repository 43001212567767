import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-checkbox-radio",
  templateUrl: "./checkbox-radio.component.html",
  styleUrls: ["./checkbox-radio.component.scss"],
})
export class CheckboxRadioComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
